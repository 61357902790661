import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Container,
  Grid,
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
  Alert
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import Copyright from './components/login/Copyright';
import logo from './retair_logo.png';
import Footer from './components/login/Footer';

const PREFIX = 'PasswordReset';

const classes = {
  paper: `${PREFIX}-paper`,
  logo: `${PREFIX}-logo`,
  submit: `${PREFIX}-submit`,
  loader: `${PREFIX}-loader`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.logo}`]: {
    width: theme.spacing(30),
    height: theme.spacing(15),
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
  [`& .${classes.loader}`]: {
    display: 'flex',
    minHeight: '350px',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const sendNewPassword = async (userData, setError, setIsSuccessChange) => {
  const response = await fetch('/change_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userData),
  });
  const result = await response.json();

  if (!result.is_ok) {
    setError(result.message);
    return;
  }
  setIsSuccessChange(result.is_ok);
};

const getPreCheckResult = async (userData, setError) => {
  const response = await fetch('/check_pre_reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userData),
  });
  const result = await response.json();

  setError(result.is_ok);
  return result;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ForgotPasswordForm = ({ setError, setIsSuccessChange, id, uuid }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    onSubmit: (values) => {
      setError(null);
      sendNewPassword({ ...values, id, uuid }, setError, setIsSuccessChange);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <TextField
        id="password"
        label={t('login.makeNewPassword')}
        variant="outlined"
        margin="normal"
        type={showPassword ? 'text' : 'password'}
        required
        fullWidth
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...formik.getFieldProps('newPassword')}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('login.saveNewPassword')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const PasswordReset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isSuccessCheck, setIsSuccessCheck] = useState(null);
  const [isSuccessChange, setIsSuccessChange] = useState(null);
  const query = useQuery();

  const id = query.get('id');
  const uuid = query.get('key');

  useEffect(() => {
    if (isSuccessCheck === null) {
      getPreCheckResult({ id, uuid }, setIsSuccessCheck);
    }
    return () => {
      setIsSuccessCheck(null);
    };
  // eslint-disable-next-line
  }, []);

  const renderError = () => (
    <>
      <Typography component="h1" variant="h5">
        {t('common.error')}
      </Typography>
      <Box py={3}>
        <Alert severity="error" sx={{ width: '100%' }}>
          <Typography variant="body2">
            {t('login.wrongResetLink')}
          </Typography>
        </Alert>
      </Box>
    </>
  );

  const renderForm = () => (
    <>
      <Typography component="h1" variant="h5">
        {t('login.makeNewPassword')}
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <ForgotPasswordForm
        setError={setError}
        setIsSuccessChange={setIsSuccessChange}
        id={id}
        uuid={uuid}
      />
    </>
  );

  const renderLoading = () => (
    <StyledContainer component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </StyledContainer>
  );

  const renderSuccess = () => (
    <Box className={classes.paper}>
      <Typography component="h1" variant="h5">
        {t('login.successfulPasswordChange')}
      </Typography>
      <Box py={3}>
        <Alert severity="success" sx={{ width: '100%' }}>
          <Typography variant="body2">
            {t('login.nowYouCanLogin')}
          </Typography>
        </Alert>
      </Box>
    </Box>
  );

  if (isSuccessCheck === null) {
    return renderLoading();
  }

  if (isSuccessChange === true) {
    return (
      <StyledContainer component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={classes.paper}>
          <img 
            onClick={() => navigate('/')} 
            src={logo} 
            className={classes.logo} 
            alt="Логотип Retair" 
          />
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
            sx={{ mb: 5 }}
          >
            {t('registration.back_to_authorization')}
          </Button>
          {renderSuccess()}
        </Box>
        <Footer />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <img 
          onClick={() => navigate('/')} 
          src={logo} 
          className={classes.logo} 
          alt="Логотип Retair" 
        />
        <Box pb={5} width="100%">
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
        </Box>
        {isSuccessCheck ? renderForm() : renderError()}
      </Box>
      <Footer />
    </StyledContainer>
  );
};

export default PasswordReset;