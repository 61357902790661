import React, { useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Container,
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import logo from './retair_logo.png';
import { localStorageKey, handleUserResponse } from './utils/auth-client';
import { AuthContext } from './context/auth-context';
import { UserContext } from './context/user-context';
import setLanguage from './utils/language';

const PREFIX = 'Register';

const classes = {
  paper: `${PREFIX}-paper`,
  logo: `${PREFIX}-logo`,
  submit: `${PREFIX}-submit`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.logo}`]: {
    width: theme.spacing(30),
    height: theme.spacing(15),
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
    height: '36px',
  },
}));

const RegisterForm = ({ setError }) => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loadRegistration, setLoadRegistration] = React.useState(false);

  const sendRegistrationData = async (userData) => {
    const response = await fetch('/register_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(userData),
    });
    const result = await response.json();
    setLoadRegistration(false);
    if (!result.is_ok) {
      setError(result.message);
      window.scrollTo(0, 0);
      return;
    }
    if (result.token) {
      authContext.setToken(result.token);
      authContext.setIsAuth(true);
      handleUserResponse(localStorageKey, result.token);
    }
    navigate('/', { success: true });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      family_name: '',
      name: '',
      middle_name: '',
      phone: '',
      organization: '',
      position: '',
      market_sector_id: 1,
      created_by: 1,
    },
    onSubmit: (values) => {
      setLoadRegistration(true);
      setError(null);
      sendRegistrationData(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <TextField
        disabled={loadRegistration}
        name="email"
        type="email"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={t('common.email')}
        autoComplete="email"
        autoFocus
        onChange={formik.handleChange}
        value={formik.values.email}
      />

      <TextField
        disabled={loadRegistration}
        name="password"
        type="password"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={t('common.password')}
        autoComplete="current-password"
        onChange={formik.handleChange}
        value={formik.values.password}
      />

      <TextField
        disabled={loadRegistration}
        name="family_name"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={t('registration.family_name')}
        autoComplete="family-name"
        onChange={formik.handleChange}
        value={formik.values.family_name}
      />

      <TextField
        disabled={loadRegistration}
        name="name"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={t('registration.name')}
        autoComplete="given-name"
        onChange={formik.handleChange}
        value={formik.values.name}
      />

      <TextField
        disabled={loadRegistration}
        name="middle_name"
        variant="outlined"
        margin="normal"
        fullWidth
        label={t('registration.middle_name')}
        autoComplete="additional-name"
        onChange={formik.handleChange}
        value={formik.values.middle_name}
      />

      <TextField
        disabled={loadRegistration}
        name="position"
        variant="outlined"
        margin="normal"
        fullWidth
        label={t('registration.position')}
        onChange={formik.handleChange}
        value={formik.values.position}
      />

      <TextField
        disabled={loadRegistration}
        name="phone"
        type="tel"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        label={t('registration.phone')}
        placeholder="+79998887766"
        onChange={formik.handleChange}
        value={formik.values.phone}
      />

      <TextField
        disabled={loadRegistration}
        name="organization"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={t('registration.organization')}
        onChange={formik.handleChange}
        value={formik.values.organization}
      />

      <input
        hidden
        name="market_sector_id"
        type="number"
        onChange={formik.handleChange}
        value={formik.values.market_sector_id}
      />
      <input
        hidden
        name="created_by"
        type="number"
        onChange={formik.handleChange}
        value={formik.values.created_by}
      />

      <Button
        disabled={loadRegistration}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        {loadRegistration ? (
          <Box sx={{ padding: '3px', width: 36, height: 36 }}>
            <CircularProgress sx={{ color: 'white', width: '100%', height: '100%' }} />
          </Box>
        ) : (
          t('registration.sign_up_btn')
        )}
      </Button>
    </form>
  );
};

const Register = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  useEffect(() => {
    setLanguage(userContext.selLanguage?.value || 'ru');
  }, [userContext.selLanguage]);

  return (
    <StyledContainer component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <img 
          onClick={() => navigate('/')} 
          src={logo} 
          className={classes.logo} 
          alt="Логотип Retair" 
        />
        <Box pb={5} width="100%">
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
        </Box>
        
        {error && <Alert severity="error">{error}</Alert>}

        <Typography component="h1" variant="h5">
          {t('registration.sign_up')}
        </Typography>
        
        <RegisterForm setError={setError} />
      </Box>
    </StyledContainer>
  );
};

export default Register;